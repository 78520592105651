import React, { useEffect, useState, useRef } from "react"
import gateway from "../../api";
import axios from "axios";
import { BlackEmail, BlackMailBox, BlackPhone, BlackPin } from "../../icons-base64/icons";
import { Helmet } from "react-helmet-async";
export default function ContactUs() {

    const [info, setInfo] = useState(null)
    const [boxHeight, setBoxHeight] = useState(0)
    const [imageHeight, setImageHeight] = useState(0)


    const boxRef = useRef(null);
    const imageRef = useRef(null);

    const fetchContactUsInfo = () => {
        axios
            .get(`${gateway()}/api/business-info/contact/`)
            .then(res => {
                setInfo(res.data)
            })
            .catch(err => { console.error("fetch contact us info error : ", err); });
    };
    useEffect(() => {

        fetchContactUsInfo()
    }, [])
    useEffect(() => {

        if (boxRef.current !== null) {
            let modal = boxRef.current
            let height = modal.getBoundingClientRect().height

            setBoxHeight(`${Math.round(height)}px`)

        }
    }, [boxRef])
    useEffect(() => {

        if (imageRef.current !== null) {
            let modal = imageRef.current
            let height = modal.getBoundingClientRect().height

            setImageHeight(`${Math.round(height)}px`)

        }
    }, [imageRef])
    return <>
        <Helmet>
            {/* Basic SEO */}
            <title>ارتباط با ما | بیزینو</title>
            <meta name="description" content="ارتباط با ما | بزینو | شرکت آرتا بادیز کارو | فرصت های سرمایه گزاری، طرح های توجیهی، مطالعات بازار و ..." />
            <meta name="keywords" content="طرح توجیهی, مطالعات بازار , بیزینو" />
            <meta name="robots" content="index, follow" />

            {/* Open Graph (Facebook, LinkedIn) */}
            <meta property="og:title" content={`ارتباط با ما | بیزینو`} />
            <meta property="og:description" content="ارتباط با ما | بزینو | شرکت آرتا بادیز کارو | فرصت های سرمایه گزاری، طرح های توجیهی، مطالعات بازار و ..." />
            <meta property="og:image" content={gateway() + info?.contactUsImage} />
            <meta property="og:url" content={`https://busiknow.com/ContactUs`} />
            <meta property="og:type" content="ارتباط با ما" />

            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="ارتباط با ما | بیزینو" />
            <meta name="twitter:description" content="ارتباط با ما | بزینو | شرکت آرتا بادیز کارو | فرصت های سرمایه گزاری، طرح های توجیهی، مطالعات بازار و ..." />


            {/* Schema.org Structured Data */}
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org/",
                    "@type": "aboutUS",
                    "name": "ارتباط با ما | بیزینو",
                    "image": gateway() + info?.contactUsImage,
                    "description": "ارتباط با ما | بیزینو ، شرکت آرتا بادیز کارو",
                    "brand": {
                        "@type": "Brand",
                        "name": "بیزینو"
                    },

                })}
            </script>
        </Helmet>
        <div className=" ">
            <div className=" z-[1] relative pulse min-h-[100px] lg:min-h-[300px]" ref={imageRef}>

                <img className="w-full " src={gateway() + info?.contactUsImage} />
            </div>
            <div className="flex justify-center" style={{ marginTop: `-${boxHeight}` }}>

                <div className="w-[70%]  lg:w-[50%]   px-4 lg:px-4 py-4 lg:py-10 z-[10]" ref={boxRef} >
                    <div className="text-2xl font-bold flex justify-center text-white ">
                        ارتباط با ما
                    </div>
                </div>
            </div>
            <div className="px-[20px] lg:px-[80px] py-4">
                جهت ارتباط با مجموعه بیزینو میتوانید از اطلاعات زیر استفاده کنید
                <div className="py-10">
                    <div className="flex items-center py-2">
                        <div className="w-1/5  md:1/3 lg:w-1/12 flex justify-center ">
                            <img src={BlackPin} width="30" />
                        </div>
                        <div className="px-3 text-[9px] text-gray-600 text-justify font-thin leading-4 flex-1">

                            <div className="text-sm font-bold mb-1">
                                آدرس
                            </div>

                            <div className="text-sm mb-1">
                                {info?.address}
                            </div>
                            <div className="text-sm mb-1">
                                {info?.address2}
                            </div>

                        </div>
                    </div>

                    <div className="flex items-center py-2">
                        <div className="w-1/5  md:1/3 lg:w-1/12 flex justify-center">
                            <img src={BlackPhone} width="27" />
                        </div>
                        <div className="px-3 text-[10px] text-gray-600 text-justify font-thin leading-4 flex-1">
                            <div className="text-sm font-bold mb-1">
                                شماره تماس
                            </div>
                            <div className="text-sm mb-1">
                                {info?.tel}
                            </div>
                            <div className="text-sm mb-1">
                                {info?.tel2}
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center py-2">
                        <div className="w-1/5  md:1/3 lg:w-1/12 flex justify-center">
                            <img src={BlackEmail} width="27" />
                        </div>
                        <div className="px-3 text-[10px] text-gray-600 text-justify font-thin leading-4 flex-1">
                            <div className="text-sm font-bold mb-1">
                                ایمیل
                            </div>
                            <div className="text-sm mb-1">
                                {info?.email}
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center py-2">
                        <div className="w-1/5  md:1/3 lg:w-1/12 flex justify-center">
                            <img src={BlackMailBox} width="33" />
                        </div>
                        <div className="px-3 text-[10px] text-gray-600 text-justify font-thin leading-4 flex-1">
                            <div className="text-sm font-bold mb-1">
                                کد پستی
                            </div>
                            <div className="text-sm mb-1">
                                {info?.postalCode}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>

}