import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/pagination";
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useEffect, useState } from 'react';
import axios from "axios";
import gateway from '../../api';
import ProductsListItem from '../Molecules/ProductsListItem';
export default function ProductsSwiper(props) {

    const [isMobile, setIsMobile] = useState(false)
    const [posts, setPosts] = useState([])
    const [openPostDetails, setOpenPostDetails] = useState(false)
    const [openDeleteConfim, setOpenDeleteConfim] = useState(false)
    const [postDetail, setPostDetail] = useState({})
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [digitalProducts, setDigitalProducts] = useState([])

    const fetchProductsByCategoryIds = () => {
        const sortBy = [
            "",
            "-updated_at"
        ].join(",");
        setIsLoading(true)
        axios
            .get(`${gateway()}/api/digitalProducts/categories/`, {  // Use new endpoint for multiple categories
                params: {
                    category_ids: [], // Send multiple category IDs as comma-separated values
                    page: 1,
                    page_size: 20,
                    min_price: 0,
                    max_price: 999999999999999,
                    sort_by: sortBy,
                },
            })
            .then((res) => {

                setIsLoading(false)
                setDigitalProducts(res.data.results.filter((p) => p.releaseType === 1))
            })
            .catch((err) => {
                setIsLoading(false)
                console.error("fetch products by category error : ", err);
            });
    };


    // const fetchDigitalProducts = () => {

    //     setIsLoading(true)
    //     axios

    //         .get(`${gateway()}/api/digitalProducts/`)

    //         .then(res => {

    //             setIsLoading(false)

    //             setDigitalProducts(res.data)
    //         })

    //         .catch(err => {
    //             console.error("fetch products error : ", err); setIsLoading(false)
    //         });

    // };
    const fetchProductsCategories = () => {
        setIsLoading(true)
        axios
            .get(`${gateway()}/api/dPCategories/`)
            .then(res => {
                setIsLoading(false)
                setCategories(res.data)
            })
            .catch(err => { setIsLoading(false); console.error("products categories info error : ", err); });
    };
    useEffect(() => {

        fetchProductsByCategoryIds()
        fetchProductsCategories();
        if (window) {
            if (window.innerWidth < 550) {
                setIsMobile(true)
            }
        }
    }, [])




    return <div className='py-5'>
        <div className=' text-right w-full flex justify-start mb-2 py-3 '>
            <p className='font-bold text-[14px] lg:text-[16px] py-1 border-b border-solid border-gray-800 w-fit'>
                جدید ترین محصولات
            </p>
        </div>
        <Swiper
            slidesPerView={isMobile ? 2 : 6}
            spaceBetween={30}
            // centeredSlides={true}
            // loop={true}

            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Navigation, Autoplay]}
            className="mySwiper text-center mt-50 mb-50 pr-60 pl-60 pb-40 pt-10"
        >
            {digitalProducts && digitalProducts.length > 0 ? digitalProducts.map((i) => {
                return <SwiperSlide>

                    <a href={`/ProductDetail?${i.id}`}>   <ProductsListItem item={i} categories={categories} />
                    </a>

                </SwiperSlide>

            }) : null}

        </Swiper>
    </div>
}