import { useState } from "react"
import PopOverDropAt from "../Atoms/PopOverDropAt";
import { useToast } from "../../Context/ToastContext";
import axios from "axios";
import gateway from "../../api";
export default function CustomOrder() {
    const showToast = useToast();



    const [fullName, setFullName] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [projectName, setProjectName] = useState("")
    const [mobile, setMobile] = useState("")
    const [serviceId, setServiceId] = useState("")
    const [openService, setOpenService] = useState(false);
    const [openTimes, setOpenTimes] = useState(false);
    const [services, setServices] = useState([{
        id: 1,
        title: "طرح توجیهی اقتصادی، فنی و مالی"
    },
    {
        id: 2,
        title: "محاسبات مالی با نرم افزار کامفار"
    }, {
        id: 3,
        title: "طرح کسب و کار (بیزینس پلن)"
    }, {
        id: 4,
        title: "مطالعات بازار"
    },
    {
        id: 5,
        title: "مطالعات فرصت سرمایه گذاری"
    },
    {
        id: 6,
        title: "مشاوره راه اندازی طرح های ایجادی و توسعه ای کشاورزی، صنعتی و خدماتی"
    },
    {
        id: 7,
        title: "دفترچه فنی و نقشه های گلخانه"
    },

    ])
    const [projectLocation, setProjectLocation] = useState("")
    const [investAmount, setInvestAmount] = useState("")
    const [timeToCall, setTimeToCall] = useState("")
    const [callTimes, setCallTimes] = useState([{
        id: 1,
        title: "8:00 - 10:00"
    },
    {
        id: 2,
        title: "10:00 - 12:00"
    }, {
        id: 3,
        title: "12:00 - 14:00"
    }, {
        id: 4,
        title: "14:00 - 16:00"
    }

    ])

    function handleServiceSelect(id) {
        debugger
        if (serviceId.length > 0 && serviceId.includes(id)) {
            let serviseIds = serviceId.filter(s => s !== id)
            setServiceId(serviseIds)
        } else {
            setServiceId([...serviceId, id])
        }

    }
    function handleTimeSelect(id) {
        debugger
        if (timeToCall.length > 0 && timeToCall.includes(id)) {
            let callTimes = timeToCall.filter(s => s !== id)
            setTimeToCall(callTimes)
        } else {
            setTimeToCall([...timeToCall, id])
        }

    }

    async function handleSubmit(event) {
        debugger
        event.preventDefault(); // Prevent default form submission

        if (!fullName || fullName.length < 2 || !fullName.includes(" ")) {
            showToast("لطفا نام و نام خانوادگی را به درستی وارد کنید", "", 4000, "warning");
            return;
        }
        if (!projectName || projectName.length < 2) {
            showToast("لطفا عنوان پروژه را به درستی وارد کنید", "", 4000, "warning");
            return;
        }
        if (!mobile || mobile.length < 10) {
            showToast("لطفا تلفن تماس را به درستی وارد کنید", "", 4000, "warning");
            return;
        }
        if (!projectLocation || projectLocation.length < 2) {
            showToast("لطفا محل اجرای پروژه را به درستی وارد کنید", "", 4000, "warning");
            return;
        }
        if (!serviceId || serviceId.length < 1) {
            showToast("لطفا حداقل یک خدمت را انتخاب کنید", "", 4000, "warning");
            return;
        }
        if (!timeToCall || timeToCall.length < 1) {
            showToast("لطفا حداقل یک زمان را جهت تماس انتخاب کنید", "", 4000, "warning");
            return;
        }

        const formData = {
            fullName: fullName,
            companyName: companyName,
            projectName: projectName,
            mobile: mobile,
            serviceId: serviceId.join(","),  // Ensure it's an array
            projectLocation: projectLocation,
            investAmount: investAmount,
            timeToCallId: timeToCall.join(","), // Ensure it's an array
        };

        try {
            const response = await axios.post(`${gateway()}/api/custom-orders/create/`, formData);
            showToast("سفارش شما با موفقیت ثبت شد", "", 4000, "success");

            // Reset form fields
            setFullName("");
            setCompanyName("");
            setProjectName("");
            setMobile("");
            setServiceId([]);
            setProjectLocation("");
            setInvestAmount("");
            setTimeToCall([]);

        } catch (error) {
            console.error("Error submitting form:", error);
            showToast("مشکلی پیش آمد، لطفا دوباره تلاش کنید", "", 4000, "error");
        }
    }
    return <div className="px-[20px] lg:px-[80px] pt-24">
        <div className="text-right font-bold  py-3 ">
            ثبت سفارش خدمات
        </div>
        <div className="text-xs text-gray-500 p-1 w-full text-right py-3" >
            این فرم برای دریافت اطلاعات اولیه شماست. پس از ثبت درخواست، با شما تماس گرفته خواهد شد و همکاران ما جزئیات تکمیلی را طی گفتگو با شما بررسی خواهند کرد.
        </div>
        <div className="flex gap-4 flex-col-reverse lg:flex-row pb-8">
            <div className="w-full lg:w-2/3 bg-gray-50 border-2 border-solid border-gray-100 rounded-lg p-5 shadow-lg">
                <div className=" flex flex-col lg:flex-row gap-3 ">
                    <div className="w-full lg:w-1/2 text-right">

                        <div className="text-xs text-gray-500 p-1" >نام و نام خانوادگی <span className="text-red-500 text-base font-bold">*</span></div>
                        <input type="text"
                            maxLength={50}
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            placeholder=""
                            className="text-sm text-gray-700 border rounded-md py-1 px-2 w-full mb-5" />

                    </div>



                    <div className="w-full lg:w-1/2 text-right">

                        <div className="text-xs text-gray-500 p-1" >نام شرکت <span className="text-red-500 text-base font-bold opacity-0">*</span></div>
                        <input type="text"
                            maxLength={50}
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            placeholder=""
                            className="text-sm text-gray-700 border rounded-md py-1 px-2 w-full mb-5" />
                    </div>


                </div>

                <div className=" flex flex-col lg:flex-row gap-3 ">
                    <div className="w-full lg:w-1/2 text-right">

                        <div className="text-xs text-gray-500 p-1" >عنوان پروژه <span className="text-red-500 text-base font-bold">*</span></div>
                        <input type="text"
                            maxLength={50}
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            placeholder=""
                            className="text-sm text-gray-700 border rounded-md py-1 px-2 w-full mb-5" />
                    </div>
                    <div className="w-full lg:w-1/2 text-right">

                        <div className="text-xs text-gray-500 p-1" >متقاضی دریافت کدام خدمات هستید؟ می‌توانید بیش از یک گزینه را انتخاب کنید. <span className="text-red-500 text-base font-bold">*</span> </div>
                        <PopOverDropAt
                            onTriggerClick={() => setOpenService(!openService)}
                            isOpen={openService}
                            DropTitle={
                                serviceId?.length > 0 ? "انتخاب شده" : "انتخاب نشده"
                            }
                            containerClassName="!bg-[#fbfbfb] mb-5"
                            dropClassName="bg-white rounded-md p-[5px]"
                        >
                            {services && services.length > 0 && services.map((s) => {
                                return <div className="flex gap-1 py-1">
                                    <input type="checkbox" className="cursor-pointer" checked={serviceId?.includes(s.title)} onChange={() => handleServiceSelect(s.title)} /><div onClick={() => handleServiceSelect(s.id)} className="text-xs text-gray-700">{s.title}</div>
                                </div>
                            })}
                        </PopOverDropAt>
                    </div>
                </div>
                <div className="w-full flex flex-col lg:flex-row gap-3">
                    <div className="w-full lg:w-1/2 text-right">

                        <div className="text-xs text-gray-500 p-1" >محل اجرای پروژه <span className="text-red-500 text-base font-bold">*</span></div>
                        <input type="text"
                            maxLength={50}
                            value={projectLocation}
                            onChange={(e) => setProjectLocation(e.target.value)}
                            placeholder=""
                            className="text-sm text-gray-700 border rounded-md py-1 px-2 w-full mb-5" />
                    </div>
                    <div className="w-full lg:w-1/2 text-right">

                        <div className="text-xs text-gray-500 p-1" >میزان سرمایه‌گذاری مورد نظر (میلیارد تومان) <span className="text-red-500 text-base font-bold opacity-0">*</span></div>
                        <input type="text"
                            maxLength={6}
                            value={investAmount}
                            onChange={(e) => setInvestAmount(e.target.value)}
                            placeholder=""
                            className="text-sm text-gray-700 border rounded-md py-1 px-2 w-full mb-5" />
                    </div>
                </div>
                <div className="w-full flex flex-col lg:flex-row gap-3">
                    <div className="w-full lg:w-1/2 text-right">

                        <div className="text-xs text-gray-500 p-1" >تلفن تماس <span className="text-red-500 text-base font-bold">*</span></div>
                        <input type="text"
                            value={mobile}
                            maxLength={11}
                            onChange={(e) => setMobile(e.target.value)}
                            placeholder=""
                            className="text-sm text-gray-700 border rounded-md py-1 px-2 w-full mb-5" />
                    </div>
                    <div className="w-full lg:w-1/2 text-right">

                        <div className="text-xs text-gray-500 p-1" >زمان مناسب جهت تماس با شما <span className="text-red-500 text-base font-bold">*</span></div>
                        <PopOverDropAt
                            onTriggerClick={() => setOpenTimes(!openTimes)}
                            isOpen={openTimes}
                            DropTitle={
                                timeToCall?.length > 0 ? "انتخاب شده" : "انتخاب نشده"
                            }
                            containerClassName="!bg-[#fbfbfb] mb-5"
                            dropClassName="bg-white rounded-md p-[5px] "
                        >
                            {callTimes && callTimes.length > 0 && callTimes.map((s) => {
                                return <div className="flex gap-1 py-1">
                                    <input type="checkbox" className="cursor-pointer" checked={timeToCall?.includes(s.title)} onChange={() => handleTimeSelect(s.title)} /><div onClick={() => handleTimeSelect(s.id)} className="text-xs text-gray-700">{s.title}</div>
                                </div>
                            })}
                        </PopOverDropAt>
                    </div>

                </div>
                <div className="lg:pt-[20px]">
                    <button onClick={handleSubmit} className="py-2 text-center bg-[#023e8a] text-white w-full lg:w-[300px] rounded-md shadow-md text-sm">
                        ثبت درخواست
                    </button>
                </div>
            </div>
            <div className=" w-full lg:w-1/3">
                <img src="/images/services.png" className="w-full" />
            </div>
        </div>
    </div>
}