import BannerGrid from "../Organisms/BannerGridORG";
import CategoryShortCuts from "../Organisms/CategoryShortCuts";
import HeroSlider from "../Organisms/HeroSlider";
import PostsSwiper from "../Organisms/PostsSwiper";
import ProductsSwiper from "../Organisms/ProductsSwiper";
import PromotionSection from "../Organisms/PromotionSection";
import { Helmet } from "react-helmet-async";
export default function Home() {

    return <>
        <Helmet>
            {/* Basic SEO */}
            <title>بیزینو | مرز ایده تا واقعیت </title>
            <meta name="description" content=" بزینو | شرکت آرتا بادیز کارو | فرصت های سرمایه گزاری، طرح های توجیهی، مطالعات بازار و ..." />
            <meta name="keywords" content="طرح توجیهی, مطالعات بازار , بیزینو" />
            <meta name="robots" content="index, follow" />

            {/* Open Graph (Facebook, LinkedIn) */}
            <meta property="og:title" content={` بیزینو | مرز ایده تا واقعیت`} />
            <meta property="og:description" content=" بزینو | شرکت آرتا بادیز کارو | فرصت های سرمایه گزاری، طرح های توجیهی، مطالعات بازار و ..." />

            <meta property="og:url" content={`https://busiknow.com`} />
            <meta property="og:type" content="بیزینو | مرز ایده تا واقعیت" />

            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content=" بیزینو | مرز ایده تا واقعیت" />
            <meta name="twitter:description" content=" بزینو | شرکت آرتا بادیز کارو | فرصت های سرمایه گزاری، طرح های توجیهی، مطالعات بازار و ..." />


            {/* Schema.org Structured Data */}
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org/",
                    "@type": "LocalBusiness",
                    "name": " بیزینو",
                    "description": " بیزینو ، شرکت آرتا بادیز کارو | ارائه طرح های توجیهی، مطالعات بازار، فرصت های سرمایه گزاری",
                    "brand": {
                        "@type": "Brand",
                        "name": "بیزینو"
                    },

                })}
            </script>
        </Helmet>
        <HeroSlider />

        <div className="px-[20px] lg:px-[80px]">

            <CategoryShortCuts />
        </div>
        <PromotionSection />
        <div className="px-[20px] lg:px-[80px]">

            <div className=""><ProductsSwiper /></div>
            <BannerGrid />
            <div className="pt-2 pb-9"><PostsSwiper /></div>

        </div>
    </>
}