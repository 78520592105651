import { useEffect, useState, useRef } from "react";
import gateway from "../../api";
import axios from "axios";
import { blackFilter, blackSort } from "../../icons-base64/icons";
import PopOverDropAt from "../Atoms/PopOverDropAt";
import DropItemAT from "../Atoms/DropItemAT";
import Pagination from "../Molecules/PaginationMol";
import PostsListItem from "../Molecules/PostsListItem";
import { Helmet } from "react-helmet-async";
export default function PostsCategoryDetail() {
    const [posts, setPosts] = useState(null);
    const [categories, setCategories] = useState(null);
    const [isCategoriesDropOpen, setIsCategoriesDropOpen] = useState(false)
    const [isDateDropOpen, setIsDateDropOpen] = useState(false)
    const [isNewestFirst, setIsnewestFirst] = useState(false)
    const [categoryLoading, setCategoryLoading] = useState(false);
    const [dPCategories, setDPCategories] = useState([])
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(100)
    const [categoryName, setCategoryName] = useState(null);
    const [isFiltersCollapsed, setIsFiltersCollapsed] = useState(false);

    const postsRef = useRef(null);
    const pageSize = 10; // Set your page size here

    const [selectedCategories, setSelectedCategories] = useState([]);

    const toggleCategorySelection = (categoryId) => {
        setSelectedCategories((prevSelected) => {
            if (prevSelected.includes(categoryId)) {
                return prevSelected.filter(id => id !== categoryId); // Deselect category
            } else {
                return [...prevSelected, categoryId]; // Select category
            }
        });
    };

    const fetchPostsByCategoryId = (categoryId, filter) => {
        setIsLoading(true)
        const sortBy = [
            !isNewestFirst ? "updated_at" : "-updated_at"
        ].join(",");

        axios
            .get(`${gateway()}/api/posts/categories/`, { // Corrected URL
                params: {
                    category_ids: categoryId,
                    page: filter ? 1 : page,
                    page_size: pageSize,
                    sort_by: sortBy,
                },
            })
            .then((res) => {
                setIsLoading(false)

                setPosts(res.data.results.filter((p) => p.releaseType === 1));
                const totalPage = Math.ceil(res.data.count / pageSize);
                setPageCount(totalPage);

            })
            .catch((err) => {
                setIsLoading(false)
                console.error("fetch posts by category error : ", err);
            });
        if (filter) {
            setPage(1)
        }
    };

    const fetchPostsCategories = (id) => {
        axios
            .get(`${gateway()}/api/categories/`)
            .then(res => {

                setCategories(res.data)
                getCategoryName(res.data, id)

            })
            .catch(err => { console.error("fetch post categories error : ", err); });
    };
    function getCategoryName(categories, categoryId) {

        let categoryName
        if (categories && categories.length > 0) {
            if (categoryId) {
                categoryName = categories.find(e => e.id === parseInt(categoryId))?.name
                if (categoryName) {
                    setCategoryName(categoryName)
                }
            }
        }
    }
    function handleFetch(filter) {
        if (window) {
            let id = window.location.href.split("?")[1]
            fetchPostsByCategoryId(id, filter);
        }
    }
    useEffect(() => {
        handleFetch()

        if (postsRef.current && page !== 1) {
            postsRef.current.scrollIntoView({ behavior: "smooth" });
        }

    }, [page]);

    useEffect(() => {
        if (window) {
            let id = window.location.href.split("?")[1]
            fetchPostsCategories(id)
        }
    }, [window])

    function handleDateDrop(state) {
        if (state)
            setIsDateDropOpen(true)
        else setIsDateDropOpen(false)
    }


    const getCategories = () => {
        setCategoryLoading(true)
        axios
            .get(`${gateway()}/api/categories/`)
            .then(res => {
                setCategoryLoading(false)
                setDPCategories(res.data)
            })
            .catch(err => { setIsLoading(false); setError(error); console.error("gaet post categories error : ", err); });
    };

    useEffect(() => {
        getCategories()
    }, [])

    return (
        <>
            <Helmet>
                {/* Basic SEO */}
                <title> {`${categoryName} مقالات `}</title>
                <meta name="description" content={`مقالات مربوط به  ${categoryName}`} />
                <meta name="keywords" content={`مقالات، ${categoryName}`} />
                <meta name="robots" content="index, follow" />

                {/* Open Graph (Facebook, LinkedIn) */}
                <meta property="og:title" content={`مقالات مربوط به  ${categoryName}`} />
                <meta property="og:description" content={`مقالات مربوط به  ${categoryName}`} />

                <meta property="og:url" content={`https://busiknow.com/PostsCategoryDetail?${window?.location.href.split("?")[1]}`} />
                <meta property="og:type" content={`مقالات مربوط به  ${categoryName}`} />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`مقالات مربوط به  ${categoryName}`} />
                <meta name="twitter:description" content={`مقالات مربوط به  ${categoryName}`} />


                {/* Schema.org Structured Data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "WebPage",
                        "name": categoryName,
                        "description": "مقالات" + categoryName,
                        "brand": {
                            "@type": "Brand",
                            "name": "بیزینو"
                        },

                    })}
                </script>
            </Helmet>

            <div className="px-[20px] lg:px-[80px] pt-28 ">
                <div className="w-full flex gap-6">
                    <div className=" text-right font-bold text-lg flex-1">
                        {categoryName}
                    </div>
                    <div className="">

                    </div>
                </div>

                <div className="flex flex-col lg:flex-row pt-4 lg:pt-10 gap-4">
                    <div className="w-full lg:w-1/6 lg:pt-2">
                        <div className="bg-white border border-solid border-gray-200 flex p-3 rounded-md cursor-pointer shadow-sm mb-3" onClick={() => setIsFiltersCollapsed(!isFiltersCollapsed)}>
                            <div className="w-1/2 flex justify-center"><img src={blackFilter} width={18} /></div>
                            <div className="w-1/2 flex justify-center"><img src={blackSort} width={18} /></div>

                        </div>
                        <div className={` bg-white rounded-md shadow-sm border border-solid border-gray-200 w-full  py-4 px-4  transition-all duration-600 ease-in-out overflow-hidden ${isFiltersCollapsed ? 'max-h-0 opacity-0' : 'max-h-[500px] opacity-100'
                            }`}>

                            <div className="text-sm text-gray-800 text-right pt-3 flex">
                                <img src={blackSort} width={18} className="mx-1" />    ترتیب نمایش
                            </div>
                            <div className="text-xs mt-3 font-thin text-black/60">
                                <div className=" items-center mt-3">

                                    <div className="w-full text-right mt-3">

                                        <div className="pr-1 pb-1 text-[9px]">بر اساس تاریخ</div>
                                        <PopOverDropAt
                                            onTriggerClick={handleDateDrop}
                                            isOpen={isDateDropOpen}
                                            DropTitle={
                                                <div>{isNewestFirst === null ? "انتخاب نشده" : isNewestFirst === true ? "جدید ترین" : "قدیمی ترین"}</div>
                                            }
                                            containerClassName="!bg-[#fbfbfb] border-[#c8cbd0]"
                                        >
                                            <div>

                                                <div onClick={() => { setIsnewestFirst(false); handleDateDrop() }}> <DropItemAT >
                                                    قدیمی ترین
                                                </DropItemAT>
                                                </div>
                                                <div onClick={() => { setIsnewestFirst(true); handleDateDrop() }}>
                                                    <DropItemAT >
                                                        جدید ترین
                                                    </DropItemAT>
                                                </div>

                                            </div>
                                        </PopOverDropAt>



                                    </div>
                                </div>
                            </div>
                            <div>
                                <button className="w-full text-xs mt-10 p-2 flex items-center justify-center shadow-lg rounded-md bg-[#262626] text-white font-bold border-solid border-[#262626] border-2 hover:lg:text-black focus:text-black hover:lg:bg-white focus:bg-white transition-colors duration-200"
                                    onClick={() => handleFetch(true)} disabled={isLoading}>
                                    {isLoading ? <img src="/images/loading.svg" width={18} /> : "اعمال"}
                                </button>
                            </div>
                        </div>
                    </div>
                    {posts && posts.length > 0 ? <div className="lg:w-5/6 grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 p-3 lg:p-0 lg:pt-2 mt-3 lg:mt-0 lg:justify-end" ref={postsRef}>
                        {posts && posts.map((post) => (
                            <a href={`/PostDetail?${post.id}`}> <PostsListItem baseUrl={true} item={post} categories={categories} /></a>

                        ))}
                    </div> : <div className="font-bold w-full h-full flex items-center justify-center text-2xl text-gray-800">موردی یافت نشد </div>}
                </div>
                {posts && posts.length > 0 && <div className="py-9 flex justify-center">
                    <div className="w-full lg:w-1/6"><Pagination currentPage={page} pageCount={pageCount} nextPage={() => setPage(page + 1)}
                        prevPage={() => setPage(page - 1)}
                    />
                    </div>
                </div>}
            </div>
        </>
    );
}


