import React, { useEffect, useState, useRef } from "react"
import gateway from "../../api";
import axios from "axios";
import ReactQuill from "react-quill";
import { Helmet } from "react-helmet-async";
export default function Policies() {

    const [info, setInfo] = useState(null)
    const [boxHeight, setBoxHeight] = useState(0)
    const [imageHeight, setImageHeight] = useState(0)


    const boxRef = useRef(null);
    const imageRef = useRef(null);

    const fetchPoliciesUsInfo = () => {
        axios
            .get(`${gateway()}/api/business-info/policies/`)
            .then(res => {
                setInfo(res.data)
            })
            .catch(err => { console.error("policies info error : ", err); });
    };
    useEffect(() => {

        fetchPoliciesUsInfo()
    }, [])
    useEffect(() => {

        if (boxRef.current !== null) {
            let modal = boxRef.current
            let height = modal.getBoundingClientRect().height

            setBoxHeight(`${Math.round(height)}px`)

        }
    }, [boxRef])
    useEffect(() => {

        if (imageRef.current !== null) {
            let modal = imageRef.current
            let height = modal.getBoundingClientRect().height

            setImageHeight(`${Math.round(height)}px`)

        }
    }, [imageRef])
    return <>
        <Helmet>
            {/* Basic SEO */}
            <title>قوانین و مقررات | بیزینو</title>
            <meta name="description" content="قوانین و مقررات | بزینو | شرکت آرتا بادیز کارو | فرصت های سرمایه گزاری، طرح های توجیهی، مطالعات بازار و ..." />
            <meta name="keywords" content="طرح توجیهی, مطالعات بازار , بیزینو" />
            <meta name="robots" content="index, follow" />

            {/* Open Graph (Facebook, LinkedIn) */}
            <meta property="og:title" content={`قوانین و مقررات | بیزینو`} />
            <meta property="og:description" content="قوانین و مقررات | بزینو | شرکت آرتا بادیز کارو | فرصت های سرمایه گزاری، طرح های توجیهی، مطالعات بازار و ..." />

            <meta property="og:url" content={`https://busiknow.com/Policies`} />
            <meta property="og:type" content="ارتباط با ما" />

            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="قوانین و مقررات | بیزینو" />
            <meta name="twitter:description" content="قوانین و مقررات | بزینو | شرکت آرتا بادیز کارو | فرصت های سرمایه گزاری، طرح های توجیهی، مطالعات بازار و ..." />


            {/* Schema.org Structured Data */}
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org/",
                    "@type": "WebPage",
                    "name": "قوانین و مقررات | بیزینو",
                    "description": "قوانین و مقررات | بیزینو ، شرکت آرتا بادیز کارو",
                    "brand": {
                        "@type": "Brand",
                        "name": "بیزینو"
                    },

                })}
            </script>
        </Helmet>
        <div className=" z-[1] relative pulse min-h-[100px] lg:min-h-[300px]" ref={imageRef}>

            <img className="w-full " src={gateway() + info?.policiesImage} />
        </div>
        <div className="flex justify-center" style={{ marginTop: `-${boxHeight}` }}>

            <div className="w-[70%]  lg:w-[50%]   px-4 lg:px-4 py-4 lg:py-10  z-[2]" ref={boxRef} >
                <div className="text-2xl font-bold flex justify-center text-white ">
                    قوانین و مقررات
                </div>
            </div>
        </div>
        <div className="px-[20px] lg:px-[80px]">

            <html>
                <ReactQuill
                    value={info?.policies}
                    readOnly={true}
                    theme={"snow"}
                    style={{ border: "none" }}
                    modules={{
                        toolbar: false,
                        clipboard: {

                        }
                    }}
                />
            </html>
        </div>
    </>
}