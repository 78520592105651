
export default function LoadingAT(props) {

    return <>
        {props.isLoading ? <div className='w-full h-screen z-[99] bg-black/20 fixed top-0 right-0 flex items-center justify-center' >
            <img src="/images/pageLoading.svg" className="opacity-90" width={75} alt="loading spinner" />
        </div> : null}
    </>


}