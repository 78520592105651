import { GrayMail, GrayOutlinedInstagram, GrayPhone, GrayPin, GrayArrow, whiteOutlinedTelegram, whiteOutlinedLinkedin } from "../../icons-base64/icons";
import { useState, useEffect } from "react";
import gateway from "../../api";
import axios from "axios";
import { useToast } from "../../Context/ToastContext";

export default function Footer() {
    const showToast = useToast();
    const [info, setInfo] = useState(null)
    const [newsLetterAddress, setNewsLetterAddress] = useState("")

    const fetchFooterInfo = () => {
        axios
            .get(`${gateway()}/api/business-info/footer/`)
            .then(res => {
                setInfo(res.data)
            })
            .catch(err => { console.error("footer info error : ", err); });
    };

    const SubscribeNewsLetter = () => {
        if (newsLetterAddress && newsLetterAddress.includes("@") && newsLetterAddress.includes(".")) {
            axios.post(

                `${gateway()}/api/newsLetter/subscribe/`,
                { email: newsLetterAddress },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            ).then(res => {
                showToast("به خبرنامه بیزینو خوش آمدید", "", 4000, "success");
            })
                .catch(err => {
                    if (err.status === 400) {
                        showToast("آدرس ایمیل تکراری میباشد", "", 4000, "error");

                    } else {
                        showToast("خطا در افزودن ایمیل به خبرنامه", "", 4000, "error");
                    }
                })
        } else {
            showToast("لطفا ابتدا آدرس ایمیل خود را به درستی وارد کنید", "", 4000, "error");

        }


    }

    useEffect(() => {

        fetchFooterInfo()
    }, [])

    return <>
        <div className="bg-[#191919]" >
            <div className="flex-col flex lg:flex-row px-[20px] lg:px-[80px] items-top">

                <div className="lg:w-1/4  w-full bg-[#262626] px-3 py-6">
                    <div className="w-full flex justify-start"><img src={gateway() + info?.logoLight} width="120" alt="busiknow.com logo" /></div>
                    <div className="text-[10px] text-gray-300 text-justify pt-6 pb-2 font-thin leading-4">
                        {info?.aboutUsSummary}
                    </div>
                    <div className="pt-3 ">
                        <div className="flex items-center py-2">
                            <div className="w-1/5  md:1/3 lg:w-1/12 flex justify-center ">
                                <img src={GrayPin} width="30" alt="busiknow.com address" />
                            </div>
                            <div className="px-3 text-[9px] text-gray-300 text-justify font-thin leading-4 flex-1">
                                <div className="font-bold text-xs">
                                    آدرس
                                </div>
                                <div className="my-2 text-xs leading-5">
                                    - {info?.address}
                                </div>
                                <div className="my-2 text-xs">
                                    - {info?.address2}
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center py-2">
                            <div className="w-1/5  md:1/3 lg:w-1/12 flex justify-center">
                                <img src={GrayPhone} width="27" alt="busiknow.com tel" />
                            </div>
                            <div className="px-3 text-[10px] text-gray-300 text-justify font-thin leading-4 flex-1">
                                <div className="font-bold text-xs">
                                    شماره تماس
                                </div>
                                <div className="my-2 text-xs">
                                    - {info?.tel}
                                </div>
                                <div className="my-2 text-xs">
                                    - {info?.tel2}
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center py-2">
                            <div className="w-1/5  md:1/3 lg:w-1/12 flex justify-center">
                                <img src={GrayMail} width="27" alt="busiknow.com email" />
                            </div>
                            <div className="px-3 text-xs text-gray-300 text-justify font-thin leading-4 flex-1">
                                <div className="">
                                    ایمیل
                                </div>
                                <div className="my-1">
                                    - {info?.email}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="lg:w-3/4 w-full lg:px-[100px] pt-6 ">
                    <div className="border-b border-solid border-white flex items-center flex-row">
                        <div className="w-full lg:w-2/3 flex-col pb-4 px-3 lg:px-0">
                            <div className="text-[10px] text-gray-300 font-thin leading-4 ">
                                ما را دنبال کنید
                            </div>
                            <div className="flex pt-2">
                                <div className="pl-1">
                                    <a href={info?.Instagram}> <img src={GrayOutlinedInstagram} width={24} alt="busiknow.com instagram" /></a>
                                </div>
                                <div className="px-1">
                                    <a href={info?.Telegram}> <img src={whiteOutlinedTelegram} width={25} alt="busiknow.com telegram" /></a>
                                </div>
                                <div className="px-1">
                                    <a href={info?.Linkedin}>  <img src={whiteOutlinedLinkedin} width={25} alt="busiknow.com linkedin" className="opacity-75" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/3 flex-col pb-4 px-3 lg:px-4">
                            <div className="text-[10px] text-gray-300 font-thin leading-4 ">
                                به خبرنامه بیزینو بپیوندید
                            </div>
                            <div className={`flex rounded-md border border-white/80 items-center py-[1px] mt-2`}>
                                <div className="w-[80%] lg:w-[90%] px-2 ">
                                    <input type="email" placeholder="ایمیل" className={`w-full bg-transparent text-white text-xs`} onChange={(e) => setNewsLetterAddress(e.target.value)} value={newsLetterAddress} />
                                </div>
                                <button className="w-[20%] lg:w-[10%] px-2 border-r border-white/60 border-dashed" onClick={() => SubscribeNewsLetter()}>
                                    <img src={GrayArrow} width="24" className="" />
                                </button>

                            </div>


                        </div>
                    </div>
                    <div className="flex flex-col lg:flex-row  pt-4">
                        <div className="w-full lg:w-1/2">
                            <div className="font-bold text-sm text-white py-2">
                                لینک های مفید
                            </div>

                            <a href="/AllProducts"><div className="font-thin py-2 text-xs text-white">
                                +  محصولات
                            </div>
                            </a>
                            <a href="/AllPosts"><div className="font-thin py-2 text-xs text-white">
                                +  مقالات
                            </div>
                            </a>
                            <a href="/AboutUs"><div className="font-thin py-2 text-xs text-white">
                                + درباره ما
                            </div>
                            </a>
                            <a href="/ContactUs"><div className="font-thin py-2 text-xs text-white">
                                + ارتباط با ما
                            </div>
                            </a>
                            <a href="/Policies"><div className="font-thin py-2 text-xs text-white">
                                + قوانین و مقررات
                            </div>
                            </a>
                        </div>
                        {/* <div className="w-full lg:w-1/3 flex justify-center">
                            <div className="font-bold text-sm text-white">
                                خدمات ما
                            </div>
                        </div> */}
                        <div className="w-full lg:w-1/2 justify-center flex gap-2 lg:gap-3 lg:justify-end py-4 lg:py-0">
                            <div className="font-bold text-sm text-white   ">
                                <a referrerpolicy='origin' target='_blank' href='https://trustseal.enamad.ir/?id=541199&Code=kAo4eO4pS0k8kPdXbwjHxhKPGqAPpgQ5'><img referrerpolicy='origin' src='https://trustseal.enamad.ir/logo.aspx?id=541199&Code=kAo4eO4pS0k8kPdXbwjHxhKPGqAPpgQ5' alt='' className="mx-auto cursor-pointer rounded-lg p-1 bg-white h-[80px] w-[80px]" code='kAo4eO4pS0k8kPdXbwjHxhKPGqAPpgQ5' /></a>
                            </div>
                            <div className="font-bold text-sm text-white   ">
                                <a target='_blank' href='/images/anjoman.jpg' >
                                    <img src='/images/icon1.png' className="rounded-lg p-1 bg-white h-[80px] w-[80px] mx-auto" />
                                </a>
                            </div>
                            <div className="font-bold text-sm text-white   ">
                                <a target='_blank' href='/images/kanun-moshaveran.jpg' >
                                    <img src='/images/icon2.png' className="rounded-lg p-1 bg-white h-[80px] w-[80px] mx-auto" />
                                </a>
                            </div>
                            <div className="font-bold text-sm text-white   ">
                                <a target='_blank' href='/images/nezam-keshavarzi.jpg' >
                                    <img src='/images/icon3.png' className="rounded-lg p-1 bg-white h-[80px] w-[80px] mx-auto" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright border-t border-solid border-orange-500 flex justify-center text-xs text-gray-300 py-2">
                کلیه حقوق این وبسایت متعلق به شرکت آرتا بادیز  کارو می‌باشد
            </div>
        </div>

    </>
}