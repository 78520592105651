import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import gateway from "../../api";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { convertToJalali, ThosandsSeperator } from "../../Utilities/UtilityMethods";
import { BlackAddedToCart, blackBoldCategory, blackCalendar, blackCategory, BlackMindMap, BlackPriceTag, BlackShoppingBag, WhiteShoppingBag } from "../../icons-base64/icons";
import { useModal } from "../../Context/AccountModalContext";
import { isTokenValid } from "../../Utilities/UtilityApis";

import { useCart } from "../../Context/CartContext";
import { Helmet } from "react-helmet-async";
export default function ProductDetail() {

    const imgLoading = useRef(null);

    const [pictureAlt, setPictureAlt] = useState('')
    const [bodyHtml, setBodyHtml] = useState(null)
    const [productId, setProductId] = useState(null)
    const [mainPic, setMainPic] = useState("")
    const [title, setTitle] = useState("")
    const [keyWords, setKeyWords] = useState("")
    const [summary, setSummary] = useState("")
    const [imgHeight, setImgHeight] = useState("0px")

    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [downloadLink, setDownloadLink] = useState('');
    const [category, setCategory] = useState(null)
    const [status, setStatus] = useState(null)
    const [categories, setCategories] = useState([])
    const [updateDate, setUpdateDate] = useState(null)
    const [price, setPrice] = useState(null)
    const [isAddedToCart, setIsAddedToCart] = useState(false)

    const { openModal } = useModal();
    const { fetchCart, cartDetail, addToCart, isCartLoading } = useCart();

    const pageRenderer = (data) => {

        if (data) {

            setSummary(data.summary)
            setKeyWords(data.keyWords)
            setTitle(data.title)
            setStatus(data.status)
            setCategory(data.category)
            setBodyHtml(data.body_html)
            setMainPic(`${data.mainPic}`)
            setPictureAlt(data.mainPicAlt)
            setDownloadLink(data.productLink)
            setUpdateDate(data.updated_at)
            setPrice((data.price))
            setProductId(data.id)

        }
    }

    const fetchDigitalProduct = (digitalProductId) => {
        setIsLoading(true)
        axios
            .get(`${gateway()}/api/digitalProducts/${digitalProductId}/`)
            .then(res => {
                setIsLoading(false)
                pageRenderer(res.data);
            })
            .catch(err => {
                setIsLoading(false);
                setError(error); console.error("fetch product error : ", err);
            });
    };

    const fetchProductsCategories = () => {
        setIsLoading(true)
        axios
            .get(`${gateway()}/api/dPCategories/`)
            .then(res => {
                setIsLoading(false)
                setCategories(res.data)
            })
            .catch(err => {
                setIsLoading(false); console.error(err);
                console.error("fetch products categories error : ", err);
            });
    };




    const handleAddToCartClick = async () => {

        const tokenIsValid = await isTokenValid();

        if (!tokenIsValid) {
            openModal();
        } else {

            if (productId !== null)
                addToCart(productId, 1)
        }
    };

    const checkIsAddedToCart = (cart, productId) => {

        let isAdded = false;
        if (cart && cart.length > 0 & productId !== null) {
            for (let i = 0; i < cart.length; i++) {
                const item = cart[i];
                if (item.product_id === productId) {
                    isAdded = true;
                    break;
                } else {
                    continue;
                }

            }
        }


        setIsAddedToCart(isAdded)
    };

    useEffect(() => {
        let id;
        if (window) {
            id = window.location.href.split("?")[1]
        }
        fetchDigitalProduct(id);
        fetchProductsCategories();
    }, [window])

    useEffect(() => {
        checkIsAddedToCart(cartDetail, productId)
    }, [cartDetail, productId, isCartLoading])


    useEffect(() => {

        if (imgLoading.current !== null && window) {
            let div = imgLoading.current
            let width = div.getBoundingClientRect().width
            setImgHeight(`${Math.round(width)}px`)

        }
    }, [imgLoading])

    return <>
        <Helmet>
            {/* Basic SEO */}
            <title>{`${title} | محصول`}</title>
            <meta name="description" content={summary} />
            <meta name="keywords" content={keyWords} />
            <meta name="robots" content="index, follow" />

            {/* Open Graph (Facebook, LinkedIn) */}
            <meta property="og:title" content={`${title} | محصول`} />
            <meta property="og:description" content={summary} />

            <meta property="og:url" content={`https://busiknow.com/ProductDetail?${window.location.href?.split("?")[1]}`} />
            <meta property="og:type" content={title} />

            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={summary} />


            {/* Schema.org Structured Data */}
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "name": title,
                    "description": summary,
                    "brand": {
                        "@type": "Brand",
                        "name": "بیزینو"
                    },
                    "offers": {
                        "@type": "Offer",
                        "priceCurrency": "IRR",
                        "price": price,
                        "availability": "https://schema.org/InStock",
                        "url": `https://busiknow.com/ProductDetail?${window.location.href?.split("?")[1]}`
                    }
                })}
            </script>
        </Helmet>
        <div className="flex md:flex-row flex-col-reverse px-[20px] lg:px-[80px] pt-24">
            <div className="w-full md:w-2/3">
                <div className="text-lg font-bold flex justify-start pt-5 lg:pt-8">
                    {isLoading ? <div className="w-[80%] md:w-[40%] h-4 "><div className="pulse rounded-lg">
                    </div></div> : title
                    }

                </div>
                <div className="text-xs flex justify-start text-justify py-3 leading-5">
                    {isLoading ? <>
                        <div className="w-[80%] md:w-[90%] flex flex-col">
                            <div className="pulse rounded-lg !h-2 mb-2">
                            </div>
                            <div className="pulse rounded-lg !h-2 mb-2 opacity-60">
                            </div> <div className="pulse rounded-lg !h-2 mb-2 opacity-80">
                            </div> <div className="pulse rounded-lg !h-2 mb-2 opacity-80">
                            </div>
                        </div>
                    </> : summary
                    }

                </div>
                {
                    categories && categories.length > 0 ? <>  <div className="flex justify-start text-[10px] items-center text-gray-600 font-bold gap-3 py-2">

                        <img src={blackBoldCategory} width={16} className="opacity-50" />
                        دسته بندی
                        <div className="rounded-full border border-solid border-gray-500 text-[9px] py-[1px] px-2">
                            {isLoading ?
                                <div className="w-6 h-2 ">
                                    <div className="pulse rounded-lg">
                                    </div>
                                </div>
                                : categories.find((e) => e.id === category)?.name
                            }
                        </div>

                    </div>
                    </> : null}
                <div className="flex justify-start text-[10px] items-center text-gray-600 font-bold gap-3 py-2">
                    <img src={blackCalendar} width={14} className="opacity-60" />
                    آخرین به‌روزرسانی
                    <div className=" text-[9px] py-[1px] px-2">
                        {isLoading ?
                            <div className="w-10 h-2 ">
                                <div className="pulse rounded-lg">
                                </div>
                            </div>
                            : convertToJalali(updateDate)
                        }
                    </div>

                </div>
                {
                    keyWords && keyWords.length > 0 ? <>
                        <div className="flex justify-start text-[10px] items-center text-gray-600 font-bold gap-3 py-2">
                            <img src={BlackMindMap} width={15} className="opacity-50" />
                            کلمات کلیدی
                            <div className=" text-[9px] py-[1px] px-2">
                                {isLoading ?
                                    <div className="w-40 h-2 ">
                                        <div className="pulse rounded-lg">
                                        </div>
                                    </div>
                                    : keyWords
                                }
                            </div>

                        </div>
                    </> : null}

                <div className="flex flex-row pt-10 lg:pt-6 justify-center lg:justify-between gap-5 items-center">
                    <div className="flex justify-start text-sm lg:text-[16px] items-center text-black/80 lg:font-bold gap-3 py-1 lg:py-6">
                        <img src={BlackPriceTag} className="opacity-80 w-[20px] lg:w-[24px] " />
                        <div className="hidden lg:inline-block">قیمت محصول</div>
                        <div className="flex gap-1 ">
                            <div>{price ? ThosandsSeperator(price) : null}</div><div>تومان</div>
                        </div>

                    </div>
                    {isCartLoading ? <button disabled className="flex gap-3 text-xs lg:text-sm  rounded-md items-center py-2 px-4 h-fit shadow-lg bg-[#081c15] text-white" onClick={() => handleAddToCartClick()}>
                        <img src="/images/loading.svg" className="w-[15px] lg:w-[24px]" /> افزودن به سبد خرید
                    </button> : isAddedToCart ?
                        <div className="bg-green-100 flex gap-3 text-xs lg:text-sm  rounded-md items-center py-2 px-4 h-fit shadow-lg">
                            <img src={BlackAddedToCart} className="w-[15px] lg:w-[24px]" />   به سبد خرید افزوده شد
                        </div>
                        : <button disabled={isLoading} className="flex gap-3 text-xs lg:text-sm  rounded-md items-center py-2 px-4 h-fit shadow-lg bg-[#081c15] text-white" onClick={() => handleAddToCartClick()}>
                            <img src={WhiteShoppingBag} className="w-[15px] lg:w-[24px]" /> افزودن به سبد خرید
                        </button>}
                </div>
            </div>
            <div className="w-full md:w-1/3 flex items-center justify-center lg:justify-end pb-4 pt-14 lg:pt-10" >
                {isLoading ?
                    <div className="w-[70%] " ref={imgLoading} style={{ height: imgHeight }}>
                        <div className="pulse rounded-lg">
                        </div>
                    </div>
                    : <img src={mainPic} className="w-[70%] shadow-md rounded-sm" alt={pictureAlt ? pictureAlt : ""} />
                }

            </div>

        </div>
        <div className="px-[20px] lg:px-[65px]">
            <html>
                {/* <div dangerouslySetInnerHTML={{ __html: bodyHtml }} /> */}
                <ReactQuill
                    value={bodyHtml}
                    readOnly={true}
                    theme={"snow"}
                    style={{ border: "none" }}
                    modules={{
                        toolbar: false,
                        clipboard: {

                        }
                    }}
                />
            </html>
        </div>
    </>
}