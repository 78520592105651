import './App.css';
import './coreOfficeGlobal.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Components/Layout';
import Home from './Components/Templates/Home';
import ProductDetail from './Components/Templates/ProductDetail';
import PostDetail from './Components/Templates/PostDetail';
import AboutUs from './Components/Templates/AboutUs';
import ContactUs from './Components/Templates/ContactUs';
import AllProducts from './Components/Templates/AllProducts';
import Policies from './Components/Templates/Policies';
import AllPosts from './Components/Templates/AllPosts';
import ProductsCategoryDetail from './Components/Templates/ProductsCategoryDetail';
import PostsCategoryDetail from './Components/Templates/PostsCategoryDetail';
import UserProfile from './Components/Templates/UserProfile';
import AccountActivation from './Components/Templates/AccountActivation';
import { ModalProvider } from './Context/AccountModalContext';
import { CartProvider } from './Context/CartContext';
import Checkout from './Components/Templates/Checkout';
import PaymentResult from './Components/Templates/PaymentResult';
import ResetPassword from './Components/Templates/ResetPassword';
import RequestResetPassword from './Components/Templates/RequestResetPassword';
import { ToastProvider } from './Context/ToastContext';
import { HelmetProvider } from "react-helmet-async";
import Sitemap from './Components/Templates/Sitemap';
import CustomOrder from './Components/Templates/CustomOrder';
function App() {

  return (
    <HelmetProvider>
      <ToastProvider>
        <ModalProvider>
          <CartProvider>
            <BrowserRouter>
              <Layout>

                <div className="App bg-gradient-to-b from-white to-slate-100">

                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/ProductDetail" element={<ProductDetail />} />
                    <Route path="/PostDetail" element={<PostDetail />} />
                    <Route path="/AboutUs" element={<AboutUs />} />
                    <Route path="/ContactUs" element={<ContactUs />} />
                    <Route path="/ProductsCategoryDetail" element={<ProductsCategoryDetail />} />
                    <Route path="/PostsCategoryDetail" element={<PostsCategoryDetail />} />
                    <Route path="/AllProducts" element={<AllProducts />} />
                    <Route path="/AllPosts" element={<AllPosts />} />
                    <Route path="/Policies" element={<Policies />} />
                    <Route path="/UserProfile" element={<UserProfile />} />
                    <Route path="/activate" element={<AccountActivation />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/paymentResult" element={<PaymentResult />} />
                    <Route path="/request-reset-password" element={<RequestResetPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/sitemap" element={<Sitemap />} />
                    <Route path="/CustomOrder" element={<CustomOrder />} />



                  </Routes>
                </div>
              </Layout>
            </BrowserRouter>
          </CartProvider>
        </ModalProvider>
      </ToastProvider>
    </HelmetProvider>
  );
}

export default App;
