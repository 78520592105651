import React, { useEffect, useState, useRef } from "react"
import gateway from "../../api";
import axios from "axios";
import ReactQuill from "react-quill";
import { Helmet } from "react-helmet-async";

export default function AboutUs() {

    const [info, setInfo] = useState(null)
    const [boxHeight, setBoxHeight] = useState(0)
    const [imageHeight, setImageHeight] = useState(0)


    const boxRef = useRef(null);
    const imageRef = useRef(null);

    const fetchAboutUsInfo = () => {
        axios
            .get(`${gateway()}/api/business-info/about/`)
            .then(res => {
                setInfo(res.data)
            })
            .catch(err => { console.error("aboutus info error : ", err); });
    };
    useEffect(() => {

        fetchAboutUsInfo()
    }, [])
    useEffect(() => {

        if (boxRef.current !== null) {
            let modal = boxRef.current
            let height = modal.getBoundingClientRect().height

            setBoxHeight(`${Math.round(height)}px`)

        }
    }, [boxRef])
    useEffect(() => {

        if (imageRef.current !== null) {
            let modal = imageRef.current
            let height = modal.getBoundingClientRect().height

            setImageHeight(`${Math.round(height)}px`)

        }
    }, [imageRef])
    return <>
        <Helmet>
            {/* Basic SEO */}
            <title>درباره ما | بیزینو</title>
            <meta name="description" content="درباره بیزینو ، شرکت آرتا بادیز کارو" />
            <meta name="keywords" content="طرح توجیهی, مطالعات بازار , بیزینو" />
            <meta name="robots" content="index, follow" />

            {/* Open Graph (Facebook, LinkedIn) */}
            <meta property="og:title" content={`درباره ما | بیزینو`} />
            <meta property="og:description" content="درباره بیزینو ، شرکت آرتا بادیز کارو" />
            <meta property="og:image" content={gateway() + info?.aboutUsImage} />
            <meta property="og:url" content={`https://busiknow.com/AboutUs`} />
            <meta property="og:type" content="about us page" />

            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="درباره ما | بیزینو" />
            <meta name="twitter:description" content="درباره بیزینو ، شرکت آرتا بادیز کارو" />
            <meta name="twitter:image" content={gateway() + info?.aboutUsImage} />

            {/* Schema.org Structured Data */}
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org/",
                    "@type": "AboutPage",
                    "name": "درباره ما | بیزینو",
                    "image": gateway() + info?.aboutUsImage,
                    "description": "درباره بیزینو ، شرکت آرتا بادیز کارو",
                    "brand": {
                        "@type": "Brand",
                        "name": "بیزینو"
                    },
                    // "offers": {
                    //   "@type": "Offer",
                    //   "priceCurrency": "IRR",
                    //   "price": product.price,
                    //   "availability": "https://schema.org/InStock",
                    //   "url": `https://example.com/products/${product.id}`
                    // }
                })}
            </script>
        </Helmet>
        <div className=" z-[1] relative pulse min-h-[100px] lg:min-h-[300px]" ref={imageRef}>

            <img className="w-full " src={gateway() + info?.aboutUsImage} />
        </div>
        <div className="flex justify-center" style={{ marginTop: `-${boxHeight}` }}>

            <div className="w-[70%]  lg:w-[50%]   px-4 lg:px-4 py-4 lg:py-10 z-[2]" ref={boxRef} >
                <div className="text-2xl font-bold flex justify-center text-white ">
                    درباره   {info?.name}
                </div>
            </div>
        </div>
        <div className="px-[20px] lg:px-[80px]">


            <ReactQuill
                value={info?.aboutUs}
                readOnly={true}
                theme={"snow"}
                style={{ border: "none" }}
                modules={{
                    toolbar: false,
                    clipboard: {

                    }
                }}
            />

        </div>
    </>
}