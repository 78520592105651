import { useEffect, useState, useRef } from "react";
import gateway from "../../api";
import axios from "axios";
import { blackFilter, BlackMobileMenu, blackSort } from "../../icons-base64/icons";
import PopOverDropAt from "../Atoms/PopOverDropAt";
import TreeStructure from "../Organisms/TreeStructure"
import DropItemAT from "../Atoms/DropItemAT";
import Pagination from "../Molecules/PaginationMol";
import PostsListItem from "../Molecules/PostsListItem";
import { Helmet } from "react-helmet-async";

export default function AllPosts() {
    const [posts, setPosts] = useState(null);
    const [categories, setCategories] = useState(null);
    const [isCategoriesDropOpen, setIsCategoriesDropOpen] = useState(false)
    const [isDateDropOpen, setIsDateDropOpen] = useState(false)
    const [isNewestFirst, setIsnewestFirst] = useState(false)
    const [categoryLoading, setCategoryLoading] = useState(false);
    const [dPCategories, setDPCategories] = useState([])
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(100)
    const [isFiltersCollapsed, setIsFiltersCollapsed] = useState(false);

    const postsRef = useRef(null);
    const pageSize = 10; // Set your page size here

    const [selectedCategories, setSelectedCategories] = useState([]);

    const toggleCategorySelection = (categoryId) => {
        setSelectedCategories((prevSelected) => {
            if (prevSelected.includes(categoryId)) {
                return prevSelected.filter(id => id !== categoryId); // Deselect category
            } else {
                return [...prevSelected, categoryId]; // Select category
            }
        });
    };

    const fetchPostsByCategoryIds = (filter) => {
        const sortBy = [
            !isNewestFirst ? "updated_at" : "-updated_at"
        ].join(",");
        setIsLoading(true)
        axios
            .get(`${gateway()}/api/posts/categories/`, {  // Use new endpoint for multiple categories
                params: {
                    category_ids: selectedCategories.join(','), // Send multiple category IDs as comma-separated values
                    page: filter ? 1 : page,
                    page_size: pageSize,
                    sort_by: sortBy,
                },
            })
            .then((res) => {
                setIsLoading(false)
                setPosts(res.data.results.filter((p) => p.releaseType === 1));
                const totalPage = Math.ceil(res.data.count / pageSize);
                setPageCount(totalPage);

            })
            .catch((err) => {
                setIsLoading(false)
                console.error("fetch posts by category error : ", err);
            });
        if (filter) {
            setPage(1)
        }
    };

    const fetchPostsCategories = () => {
        axios
            .get(`${gateway()}/api/categories/`)
            .then(res => {

                setCategories(res.data)
            })
            .catch(err => { console.error("posts categories error : ", err); });
    };

    useEffect(() => {

        fetchPostsByCategoryIds();
        fetchPostsCategories()
        if (postsRef.current && page !== 1) {
            postsRef.current.scrollIntoView({ behavior: "smooth" });
        }

    }, [page]);

    function dropTrigger(state) {
        if (state)
            setIsCategoriesDropOpen(true)
        else setIsCategoriesDropOpen(false)
    }

    function handleDateDrop(state) {
        if (state)
            setIsDateDropOpen(true)
        else setIsDateDropOpen(false)
    }


    const getCategories = () => {
        setCategoryLoading(true)
        axios
            .get(`${gateway()}/api/categories/`)
            .then(res => {
                setCategoryLoading(false)
                setDPCategories(res.data)
            })
            .catch(err => { setIsLoading(false); setError(error); console.error("get posts categories error : ", err); });
    };

    useEffect(() => {
        getCategories()
    }, [])

    return (
        <>
            <Helmet>
                {/* Basic SEO */}
                <title>همه مقالات | بیزینو</title>
                <meta name="description" content="مقالات رایگان در رابطه با فرصت های سرمایه گزاری، طرح های توجیهی، مطالعات بازار و ..." />
                <meta name="keywords" content="طرح توجیهی, مطالعات بازار , بیزینو" />
                <meta name="robots" content="index, follow" />

                {/* Open Graph (Facebook, LinkedIn) */}
                <meta property="og:title" content={`همه مقالات | بیزینو`} />
                <meta property="og:description" content="مقالات رایگان در رابطه با فرصت های سرمایه گزاری، طرح های توجیهی، مطالعات بازار و ..." />

                <meta property="og:url" content={`https://busiknow.com/AllPosts`} />
                <meta property="og:type" content="مقالات آموزشی" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="همه مقالات | بیزینو" />
                <meta name="twitter:description" content="مقالات رایگان در رابطه با فرصت های سرمایه گزاری، طرح های توجیهی، مطالعات بازار و ..." />


                {/* Schema.org Structured Data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "WebPage",
                        "name": "مقالات | بیزینو",
                        "description": "مقالات آموزشی ، شرکت آرتا بادیز کارو",
                        "brand": {
                            "@type": "Brand",
                            "name": "بیزینو"
                        },
                        // "offers": {
                        //   "@type": "Offer",
                        //   "priceCurrency": "IRR",
                        //   "price": product.price,
                        //   "availability": "https://schema.org/InStock",
                        //   "url": `https://example.com/products/${product.id}`
                        // }
                    })}
                </script>
            </Helmet>
            <div className="px-[20px] lg:px-[80px] pt-28 ">
                <div className="w-full flex gap-6">
                    <div className=" text-right font-bold text-lg flex-1">
                        مقالات
                    </div>
                    <div className="">

                    </div>
                </div>

                <div className="flex flex-col lg:flex-row pt-4 lg:pt-10 gap-4">
                    <div className="w-full lg:w-1/6 lg:pt-2">
                        <div
                            className="bg-white border border-solid border-gray-200 flex p-3 rounded-md cursor-pointer shadow-sm"
                            onClick={() => setIsFiltersCollapsed(!isFiltersCollapsed)}
                        >
                            <div className="w-1/2 flex justify-center">
                                <img src={blackFilter} width={18} />
                            </div>
                            <div className="w-1/2 flex justify-center">
                                <img src={blackSort} width={18} />
                            </div>
                        </div>

                        <div
                            className={`bg-white rounded-md mt-1 shadow-sm border border-solid border-gray-200 w-full py-4 px-4 transition-all duration-600 ease-in-out overflow-hidden ${isFiltersCollapsed ? 'max-h-0 opacity-0' : 'max-h-[500px] opacity-100'
                                }`}
                        >
                            <div className="text-sm text-gray-800 text-right flex">
                                <img src={blackFilter} width={18} className="mx-1" />
                                فیلتر
                            </div>
                            <div className="text-xs mt-3 font-thin text-black/60">
                                <div className="flex gap-4 items-center mt-3">
                                    <div className="w-full text-right">
                                        <div className="pr-1 pb-1 text-[9px]">دسته‌بندی</div>
                                        <PopOverDropAt
                                            onTriggerClick={dropTrigger}
                                            isOpen={isCategoriesDropOpen}
                                            DropTitle={
                                                categoryLoading ? (
                                                    <img src="/images/loading.svg" width={18} />
                                                ) : (
                                                    <div>
                                                        {selectedCategories && selectedCategories.length > 0
                                                            ? 'انتخاب شده'
                                                            : 'انتخاب نشده'}
                                                    </div>
                                                )
                                            }
                                            containerClassName="!bg-[#fbfbfb] border-[#c8cbd0]"
                                        >
                                            <div>
                                                <TreeStructure
                                                    data={dPCategories}
                                                    selectedCategories={selectedCategories}
                                                    toggleCategorySelection={toggleCategorySelection}
                                                    isOpen={isCategoriesDropOpen}
                                                />
                                            </div>
                                        </PopOverDropAt>
                                    </div>
                                </div>
                            </div>
                            <div className="text-sm text-gray-800 text-right mt-4 pt-3 border-t border-solid border-gray-300 flex">
                                <img src={blackSort} width={18} className="mx-1" />
                                ترتیب نمایش
                            </div>
                            <div className="text-xs mt-3 font-thin text-black/60">
                                <div className="items-center mt-3">
                                    <div className="w-full text-right mt-3">
                                        <div className="pr-1 pb-1 text-[9px]">بر اساس تاریخ</div>
                                        <PopOverDropAt
                                            onTriggerClick={handleDateDrop}
                                            isOpen={isDateDropOpen}
                                            DropTitle={
                                                <div>
                                                    {isNewestFirst === null
                                                        ? 'انتخاب نشده'
                                                        : isNewestFirst === true
                                                            ? 'جدید ترین'
                                                            : 'قدیمی ترین'}
                                                </div>
                                            }
                                            containerClassName="!bg-[#fbfbfb] border-[#c8cbd0]"
                                        >
                                            <div>
                                                <div
                                                    onClick={() => {
                                                        setIsnewestFirst(false);
                                                        handleDateDrop();
                                                    }}
                                                >
                                                    {' '}
                                                    <DropItemAT>قدیمی ترین</DropItemAT>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        setIsnewestFirst(true);
                                                        handleDateDrop();
                                                    }}
                                                >
                                                    <DropItemAT>جدید ترین</DropItemAT>
                                                </div>
                                            </div>
                                        </PopOverDropAt>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button
                                    className="w-full text-xs mt-10 p-2 flex items-center justify-center shadow-lg rounded-md bg-[#262626] text-white font-bold border-solid border-[#262626] border-2 hover:lg:text-black focus:text-black hover:lg:bg-white focus:bg-white transition-colors duration-200"
                                    onClick={() => fetchPostsByCategoryIds(true)}
                                    disabled={isLoading}
                                >
                                    {isLoading ? <img src="/images/loading.svg" width={18} /> : "اعمال"}
                                </button>
                            </div>
                        </div>
                    </div>

                    {posts && posts.length > 0 ? <div className="lg:w-5/6 grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 p-3 lg:p-0 lg:pt-2 mt-3 lg:mt-0 lg:justify-end" ref={postsRef}>
                        {posts && posts.map((post) => (
                            <a href={`/PostDetail?${post.id}`}> <PostsListItem baseUrl={true} item={post} categories={categories} /></a>

                        ))}
                    </div> : <div className="font-bold w-full h-full flex items-center justify-center text-2xl text-gray-800">موردی یافت نشد </div>}
                </div>
                <div className="py-9 flex justify-center">
                    <div className="w-full lg:w-1/6"><Pagination currentPage={page} pageCount={pageCount} nextPage={() => setPage(page + 1)}
                        prevPage={() => setPage(page - 1)}
                    />
                    </div>
                </div>
            </div>
        </>
    );
}


